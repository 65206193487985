const Flickity = require('flickity')
const cars = require('./chunks/base/pages');


function fillHotOffers(n) {
  const limit = cars.length - 1;
  const used = []

  function getRandoms(arr) {
    const random = Math.floor(Math.random() * limit);
    for (let i = 0; i < arr.length; i++) {
      if (random === arr[i]) return getRandoms(arr);
    }
    return random;
  }
  for (let i = 0; i < n; i++) {
    used.push(getRandoms(used));
  }

  return used.reduce((markup, i) => {
    return markup += `
      <div class="__ad">
        <div class="container">
          <a href="./${cars[i].filename}.html">
            <img src="/assets/images/store/${i}/0.jpg" alt="${cars[i].title}" style="max-width: 100%;max-height: 100%;">
          </a>

          <a href="./${cars[i].filename}.html"><h4>${cars[i].title}</h4></a>

          <span class="price">${cars[i].price}</span>
        </div>
      </div>
    `;
  }, ``);
}

function _onload() {
  
  if (document.getElementById('slider')) {
    const slider = new Flickity('#slider', {
      cellAlign: 'center',
      wrapAround: true
    });
    window.adSlider = slider;
  }

  if (document.getElementById('aside-listing')) {
    document.getElementById('aside-listing').innerHTML = fillHotOffers(2);
  }

}

window.addEventListener('load', _onload);