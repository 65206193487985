module.exports = {
  "cranes": [
    {
      "images": new Array(6),
      "title": "2005 DAF CF75.250",
      "price": "£23,750",
      "desc": "2005 ’55 DAF CF 22 250 Fitted with 24ft double alloy dropside with hardwood floor. Rear mounted Atlas 165.2 double extension crane with rotating hook. Ex fleet in good condition. Vehicle and crane to be supplied with full tests done circa 420,000 km’s - £23,750 + VAT",
    }, {
      "images": new Array(6),
      "title": "2007 MAN TGA18.320",
      "price": "£15,500",
      "desc": "2007 Man TGA18.320 Sleeper Cab, 4x2, 18ton, 26ft Flatbed c/w Twist Locks, 23ton Fassi Crane, Double Extension, Remote Control, Camro 9ton Front Axle, Any Inspection Welcome £15,500 plus vat. 4x2",
    }, {
      "images": new Array(6),
      "title": "2009 MAN TGS26.400",
      "price": "£27,500",
      "desc": "2009 MAN TGS26.400 Sleeper Cab 6x2, PM 40ton Remote Controlled Crane, 5 Extensions, 24ft Extendable Body, Twist Lock, 9ton Front Axle, Pipped for Drawbar, Manual, Any Inspection Welcome. £27,500 plus vat 6x2",
    },
  ],  
  "exc": [
    {
      "images": new Array(6),
      "title": "JCB 8018 CTS",
      "price": "£10,250",
      "desc": `<table><tbody><tr><th colspan="2">BASIC INFORMATION</th></tr><tr><td class="name">Product group</td><td class="value"><span itemprop="category">Mini excavators &lt; 7t (Mini diggers)</span></td></tr><tr><td class="name">Brand / model</td><td class="value"><span itemprop="name">JCB 8018 CTS</span></td></tr><tr><td class="name">Year of manufacture</td><td class="value">2010</td></tr><tr><td class="name">Hours of use</td><td class="value">1,435 h</td></tr><tr><td class="name">Machine Location</td><td class="value">PERSHORE</td></tr><tr class="hidden-detail sm-hide"><th colspan="2">DETAILS</th></tr><tr class="hidden-detail sm-hide"><td class="name">General grade (1 min - 5 max)</td><td class="value">4</td></tr><tr class="hidden-detail sm-hide"><td class="name">Amount of previous owners</td><td class="value">1</td></tr><tr class="hidden-detail sm-hide"><td class="name">Gross weight</td><td class="value">1,900 kg</td></tr><tr class="hidden-detail sm-hide"><td class="name">CE marked</td><td class="value">Yes</td></tr><tr class="hidden-detail sm-hide"><td class="name">Original colour</td><td class="value">YES</td></tr><tr class="hidden-detail sm-hide"><td class="name">Undercarriage</td><td class="value">30 %</td></tr><tr class="hidden-detail sm-hide"><td class="name">Accessories</td><td class="value"><ul><li>- Hammer hydr. function</li><li>- Standard digging bucket</li><li>- Cable bucket</li><li>- Ditching/grading bucket</li></ul></td></tr></tbody></table>`,
    }, {
      "images": new Array(6),
      "title": "TAKEUCHI TB016",
      "price": "£6,250",
      "desc": `<table><tbody><tr><th colspan="2">BASIC INFORMATION</th></tr><tr><td class="name">Product group</td><td class="value"><span itemprop="category">Mini excavators &lt; 7t (Mini diggers)</span></td></tr><tr><td class="name">Brand / model</td><td class="value"><span itemprop="name">Takeuchi TB016</span></td></tr><tr><td class="name">Year of manufacture</td><td class="value">2008</td></tr><tr><td class="name">Hours of use</td><td class="value">5,172 h</td></tr><tr class="hidden-detail sm-hide"><th colspan="2">DETAILS</th></tr><tr class="hidden-detail sm-hide"><td class="name">Internal stock No.</td><td class="value">162605</td></tr><tr class="hidden-detail sm-hide"><td class="name">Gross weight</td><td class="value">1,600 kg</td></tr><tr class="hidden-detail sm-hide"><td class="name">Engine output</td><td class="value">1,011 kW (1,375 hp)</td></tr><tr class="hidden-detail sm-hide"><td class="name">Attachments</td><td class="value">c/w hitch and 4 buckets</td></tr></tbody></table>`,
    }, {
      "images": new Array(1),
      "title": "CATERPILLAR 345 B L ME",
      "price": "£29,000",
      "desc": `<table><tbody><tr><th colspan="2">BASIC INFORMATION</th></tr><tr><td class="name">Product group</td><td class="value"><span itemprop="category">Crawler excavators</span></td></tr><tr><td class="name">Brand / model</td><td class="value"><span itemprop="name">Caterpillar 345 B L ME</span></td></tr><tr><td class="name">Year of manufacture</td><td class="value">1998</td></tr><tr><td class="name">Hours of use</td><td class="value">32,000 h</td></tr><tr class="hidden-detail sm-hide"><th colspan="2">DETAILS</th></tr><tr class="hidden-detail sm-hide"><td class="name">Amount of previous owners</td><td class="value">1</td></tr><tr class="hidden-detail sm-hide"><td class="name">General grade (1 min - 5 max)</td><td class="value">5</td></tr><tr class="hidden-detail sm-hide"><td class="name">Undercarriage</td><td class="value">70</td></tr><tr class="hidden-detail sm-hide"><td class="name">CE marked</td><td class="value">Yes</td></tr><tr class="hidden-detail sm-hide"><td class="name">Other information</td><td class="value"><span itemprop="description">CAT 345B LME, Year 1998, 32147 hours, 1 owner from new and very well looked after during its life using all original CAT parts. Undercarriage is very good and the machine runs very well. Any inspection welcome</span></td></tr></tbody></table>`,
    },
  ],
  "trailers": [
    {
      "images": new Array(3),
      "title": "2007 ANDOVER SFCL41",
      "price": "£12,000",
      "desc": "Andover triaxle sloping bed lowloader,hyd flip tow ramps,12 months test,nice condition. FuelType: Diesel Drive: tra_3ax Transmission: unknown Cab: unknown Model: SFCL41",
    }, {
      "images": new Array(6),
      "title": "2008 BROSHUIS TRIAXLE STEPFRAME",
      "price": "£19,750",
      "desc": "Broshius 73 Ton triaxle stepframe lowloader, beavertail + hyd ramps,rear steer axle,autolube, swing up outriggers.Ex MOD in immaculate condition,hardly been used.Choice of 2. FuelType: Diesel Drive: tra_3ax Transmission: Automatic Cab: unknown Model: TRIAXLE STEPFRAME",
    }, {
      "images": new Array(7),
      "title": "2006 DENNISON EXTENDABLE TRACTOR CARRIER",
      "price": "£18,000",
      "desc": "2006 Dennison Extendable Low Loader Tractor Carrier, 1 Owner Trailer In Red, ROR Axles with Drum Brakes, Tested March 2019, Manual Pull Out Ramps, Remote Winch, Very Straight & Well Maintained Trailer, Any Inspection Welcomed, Trailer has been used for our own internal movements and has been replaced with a New Trailer, £18,000+VAT, Call! Model: EXTENDABLE TRACTOR CARRIER",
    },
  ]
};